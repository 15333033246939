import { ProcessLog } from '@/found/modules';
import BasePage from '../../../../../../components/table_page';
import Form from '../form';

export default {
  name: 'tasting_wine_order',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
    ProcessLog,
  },
  created() {
    this.getConfigList('tpm_tasting_wine_order_list');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      const { approveStatus, processId } = row;
      if (code === 'edit' && (approveStatus === 'approving' || approveStatus === 'approved')) {
        return false;
      }
      if (code === 'process_log' && !processId) {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (val.code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row: { processNo: row.processId },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
    },
    // 提交审批回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };
      this.$emit('approve', configParams);
    },
  },
};
